import {twMerge} from 'tailwind-merge';
import * as Dialog from '@radix-ui/react-dialog';
import {usePreventScroll} from '@react-aria/overlays';
import {VisuallyHidden} from '@radix-ui/react-visually-hidden';
import Smooth from './Smooth';

const overlayActiveStyle: React.CSSProperties = {opacity: 0.8};

const overlayInactiveStyle: React.CSSProperties = {opacity: 0};

const wrapperActiveStyle: React.CSSProperties = {opacity: 1, transform: 'translateY(0px)'};

const wrapperInactiveStyle: React.CSSProperties = {
  opacity: 0,
  transform: 'translateY(100px)'
};

type Props = {
  onClose: () => void;
  children: any;
  contentId?: string;
  wrapperStyle?: React.CSSProperties;
  overlayClassName?: string;
  closeOnEscape?: boolean;
  closeOnClickOutside?: boolean;
  extraComponent?: React.ReactNode;
  title?: string;
  container?: React.RefObject<HTMLDivElement>;
};

const RadixModal = ({
  onClose,
  children,
  contentId,
  wrapperStyle,
  closeOnEscape = true,
  closeOnClickOutside = true,
  extraComponent,
  title,
  container,
  overlayClassName
}: Props) => {
  usePreventScroll();

  return (
    <Dialog.Root open onOpenChange={(open: boolean) => (open ? null : onClose())} modal={false}>
      <Dialog.Portal container={container?.current}>
        <Smooth
          className={twMerge(
            'modal-overlay bg-[rgba(0,0,0,0.2)] w-full h-full fixed z-[22] top-0 left-0',
            overlayClassName
          )}
          activeStyle={overlayActiveStyle}
          inactiveStyle={overlayInactiveStyle}
        />

        <Dialog.Content
          aria-describedby={undefined}
          className="fixed z-[23] top-0 left-0 w-full h-full"
          id={contentId}
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => {
            if (!closeOnEscape) e.preventDefault();
          }}
        >
          {title ? (
            <VisuallyHidden asChild>
              <Dialog.Title>{title}</Dialog.Title>
            </VisuallyHidden>
          ) : null}

          <Smooth
            className="modal-content-wrapper absolute top-0 left-0 w-full h-full flex justify-center items-center"
            style={wrapperStyle}
            activeStyle={wrapperActiveStyle}
            inactiveStyle={wrapperInactiveStyle}
          >
            <div className="absolute w-full h-full" onClick={closeOnClickOutside ? onClose : undefined} />

            {children}
          </Smooth>

          {extraComponent}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default RadixModal;
